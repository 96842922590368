.table-component {
	table {
		border: none !important;
	}
	.ant-table {
		.ant-table-container {
			&:before {
				box-shadow: none !important;
			}
			.ant-table-content {
				table {
					thead,
					.ant-table-thead {
						tr {
							th {
								border-radius: 0;
								font-size: 12.5px !important;
							}
						}
					}
					tbody,
					.ant-table-tbody {
						tr {
							td {
								font-size: 13px !important;
							}
						}
					}
				}
				.ant-table-tbody {
					.ant-table-row {
						td {
							padding: 8px 8px !important;
						}
					}
				}
			}
		}
	}
}
