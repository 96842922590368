.routeCard {
	.routeCard-pdf {
		.heading {
			font-size: 14px;
		}
		.heading,
		.item,
		.total {
			td {
				text-align: center;
			}
			td:nth-child(2) {
				text-align: center;
			}
		}
		.routeCard-pdf-container {
			border: 1px solid #000;
			padding: 0;
		}
		max-width: 800px;
		margin: auto;
		padding: 30px;
		// border: 1px solid #eee;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
		font-size: 16px;
		line-height: 24px;
		// font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
		color: #000;
		.bordered-bottom {
			// border: none !important;
			// border-bottom: 1px solid #eee;
		}
		.routeCard-footer {
			display: flex;
			justify-content: space-between;
			font-weight: bold;
			padding: 10px;
			padding-top: 30px;
		}
		.signArea {
			display: flex;
			border-top: 1px solid #000;
			margin-top: 20px;
			.left {
				padding: 10px;
				width: 70%;
				border-right: 1px solid #000;
				min-height: 100px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				.title {
					font-size: 14px;
					border: 1px solid #000;
				}
				.paragraph {
					font-size: 10px;
					line-height: normal;
				}
			}
			.sign {
				padding: 10px;
				width: 30%;
				color: #f77b3b;
				font-size: 10px !important;
				font-weight: bold !important;
			}
		}
		table {
			border: none !important;
		}
		.value-table {
			border: 1px solid !important;
		}
	}
	
	.routeCard-pdf table {
		width: 100%;
		line-height: inherit;
		text-align: left;
	}
	
	.routeCard-pdf table td {
		padding: 5px;
		vertical-align: top;
	}
	
	.routeCard-pdf table tr.routeCard-top table td {
		padding-bottom: 20px;
	}
	
	.routeCard-pdf {
		table {
			td {
				// display: flex;
				// align-items: center;
			}
		}
	}
	
	.routeCard-pdf table tr.routeCard-top table td.title {
		border: 1px solid #000;
		font-size: 15px;
		text-align: center;
		width: 100%;
		// line-height: 45px;
		font-weight: bold;
		color: #000;
		display: flex;
		padding: 10px;
		.content {
			text-align: center;
			width: 100%;
			height: max-content;
			.name {
				font-size: 20px;
				color: orange;
			}
			.address,
			.contact {
				font-size: 12px;
				line-height: normal;
			}
		}
		.left {
			width: 75%;
			display: flex;
			align-items: center;
			img {
				width: 100px;
			}
		}
		.right {
			text-align: right;
			width: 25%;
			font-weight: bold;
			font-size: 12px;
			display: flex;
			justify-content: flex-end;
			.content {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: max-content;
				.tax-routeCard {
					font-size: 18px;
					font-weight: normal;
				}
				.copy {
					font-size: 10px;
				}
			}
		}
	}
	
	.routeCard-pdf table,
	th,
	td {
		border-collapse: collapse;
		height: 35px;
	}
	
	.routeCard-pdf table tr.information table td {
		padding-bottom: 20px;
	}
	
	.routeCard-pdf table tr.heading td {
		border: 1px solid #000;
		font-weight: bold;
	}
	
	.routeCard-pdf table tr.details td {
		padding-bottom: 20px;
	}
	
	.routeCard-pdf table tr.item td {
		// border-bottom: 1px solid #eee;
	}
	
	.routeCard-pdf table tr.item.last td {
		border-bottom: none;
	}
	
	.routeCard-pdf table tr.total td:nth-child(2) {
		border-top: 2px solid #eee;
		font-weight: bold;
	}
	
	@media only screen and (max-width: 600px) {
		.routeCard-pdf table tr.routeCard-top table td {
			width: 100%;
			display: block;
			text-align: center;
		}
	
		.routeCard-pdf table tr.information table td {
			width: 100%;
			display: block;
			text-align: center;
		}
	}
	
	/** RTL **/
	.routeCard-pdf.rtl {
		direction: rtl;
		// font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
	}
	
	.routeCard-pdf.rtl table {
		text-align: right;
	}
	
	.routeCard-pdf.rtl table tr td:nth-child(2) {
		text-align: left;
	}
	
	.information {
		td {
			table {
				tr {
					td {
						padding: 10px;
						&:nth-child(2),
						&:nth-child(4) {
							font-weight: bold;
						}
					}
				}
			}
		}
	
		.row {
			width: 100%;
			display: flex;
			.label {
				width: 50%;
				font-weight: bold;
				font-size: 14px;
				text-align: right;
			}
			.value {
				padding-left: 0;
				width: 50%;
				font-size: 14px;
				text-align: left;
				font-weight: normal;
			}
		}
		.no_padding {
			padding-right: 0;
			.row {
				margin-right: 0;
				.value {
					padding-right: 0;
				}
			}
		}
	}
	
	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	
	/* Firefox */
	input[type='number'] {
		// -moz-appearance: textfield;
	}
	
	.toArea {
		p {
			margin-bottom: 10px;
		}
		.details {
			font-size: 14px;
		}
	}
	
}