.login_container {
	background-image: url("../../../public/assets/background.png");
	background-size: auto 100%;
	.login_area {
		display: block;
		height: 100%;
		background-color: #f9f8fb;
		background-color: #fff;
		// box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, .1), 0px 2px 4px -1px rgba(0, 0, 0, .06), 0px 0px 0px 1px rgba(0, 0, 0, .05);
		// min-height: max-content;
		border-radius: 8px;
		.right_area {
			background-color: #fff;
			padding: 20px;
			box-shadow: 0px 8px 28px 0px rgba(61, 72, 107, 0.2);
			height: 100%;
			.logo_class {
				padding: 0;
				justify-content: flex-start;
			}
			// .ant-input-affix-wrapper {
			// 	padding-left: 0;
			// 	padding-top: 0;
			// 	padding-bottom: 0;
			// }
			.ant-input,
			button {
				height: 44px;
				box-sizing: border-box;
				// text-indent: 12px;
				font-size: 16px;
				// border-radius: 2px !important;
			}
			.ant-tabs {
				.ant-tabs-nav-wrap {
					.ant-tabs-tab {
						.ant-tabs-tab-btn {
							font-size: 16px;
						}
					}
				}
			}
		}
		.slider_area {
			padding: 40px;
			.slick-list {
				.slick-slide {
					.slider_item {
						display: flex !important;
						flex-direction: column;
						align-items: center;
						img {
							width: 300px;
							object-fit: contain;
							align-self: center;
						}
						.title {
							font-size: 16px;
							font-weight: 600;
							margin: 20px 0;
							text-align: center;
						}
						.para {
							text-align: center;
							line-height: 20px;
						}
					}
				}
			}
		}
	}
	.login_image {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding-top: 30px;
		img {
			height: 100%;
			width: 100%;
			object-fit: contain;
		}
	}
	.nav-link {
		font-weight: bold;
		text-decoration: underline;
		color: #25b394;
	}
}

.content-left {
	padding: 3rem 2.5rem;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.shipping-head {
	font-weight: 700;
	font-size: 28px;
	line-height: 38px;
	margin-bottom: 2.25rem;
	position: relative;
}

.shipping-head:after {
	content: "";
	width: 90px;
	height: 3px;
	background: #ff3131;
	position: absolute;
	left: 0;
	bottom: -8px;
}

.shipping-subhead-login {
	font-size: 1.5rem;
	font-weight: 600;
	line-height: 2rem;
}

.icon-wrap {
	font-size: 1.125rem;
	font-weight: 500;
	line-height: 1.75rem;
	color: #555e7a;
	display: flex;
	align-items: center;
	margin-top: 1rem;
}

.img-contain {
	margin-right: 16px;
	display: none;
}
