// Override default variables before the import
$primary: #1b59a8;

// @import "~bootstrap/scss/bootstrap.scss";

.btn-primary {
	background-color: $primary !important;
	border-color: $primary !important;
}

.btn-outline-primary {
	color: $primary !important;
	border-color: $primary !important;
}

.btn-outline-primary:hover {
	color: white !important;
	background-color: $primary !important;
	border-color: $primary !important;
}
