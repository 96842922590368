.lrCopy {
	.lrCopy-pdf {
		.heading {
			font-size: 14px;
		}
		.lrCopy-pdf-container {
			padding: 0;
			.content {
				div {
					height: 14px;
					&:last-child {
						height: inherit;
					}
				}
			}
		}
		max-width: 800px;
		margin: auto;
		padding: 10px;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
		font-size: 10px;
		line-height: 24px;
		// text-transform: uppercase;
		color: #000;
		.row-style {
			display: flex;
			.checkbox-container {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				width: 30%;
				.checkbox {
					width: 20px;
					height: 20px;
					border: 1px solid #000;
					background-color: white;
					margin-right: 10px;
					// &:before {
					// 	content: '';
					// 	width: 20px;
					// 	height: 20px;
					// 	border: 1px solid #000;
					// 	background-color: white;
					// 	position: absolute;
					// 	left: 55px;
					// 	top: 50%;
					// 	transform: translate(0, -50%);
					// }
				}
			}
			.text {
			}
		}
		.grey {
			background: lightgray;
		}
		.center {
			text-align: center;
		}
		.bold {
			font-weight: bold;
			// background-color: #f5f5f5;
		}
		table {
			width: 100%;
			line-height: inherit;
			text-align: left;
		}
		table,
		th,
		td {
			border: 1px solid black;
		}
		table {
			tr {
				td {
					width: 14%;
					padding-left: 0.5em;
				}
			}
		}
		.no-border {
			border: none;
		}
	}

	@media only screen and (max-width: 600px) {
		.lrCopy-pdf table tr.lrCopy-top table td {
			width: 100%;
			display: block;
			text-align: center;
		}

		.lrCopy-pdf table tr.information table td {
			width: 100%;
			display: block;
			text-align: center;
		}
	}

	.toArea {
		p {
			margin-bottom: 10px;
		}
		.details {
			font-size: 14px;
		}
	}
}
