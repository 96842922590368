.ant-form-item {
	margin-bottom: 10px;
}

.ant-divider.ant-divider-horizontal {
	margin: 5px 0;
}

.ant-table {
	font-size: 14px !important;
}

.custom-table {
	.ant-table-thead > tr > th,
	.ant-table-tbody > tr > .ant-table-row .ant-table tfoot > tr > th,
	.ant-table tfoot > tr > .ant-table-row {
		padding: 8px 8px 8px 8px !important;
	}
	th {
		border-top: 0 !important;
		padding: 0 0 0 30px !important;
		background: white !important;
		border-bottom: 1px solid #dddddd !important;
		font-weight: bold !important;
		color: #61616b !important;
		line-height: 1.42857 !important;
		letter-spacing: 0.7px !important;
		max-height: 35px !important;
		font-size: 14px !important;
	}

	tbody tr:hover td {
		background-color: #f5f5f5 !important;
	}

	tr > .ant-table-row {
		line-height: 1.42857 !important;
		padding: 8px !important;
		vertical-align: top !important;
		color: #2e2e2e !important;
		font-size: 10px !important;
	}
	.ant-spin-nested-loading {
		.ant-spin-spinning {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}

.ant-btn-primary {
	span {
		svg {
			vertical-align: initial !important;
		}
	}
}

.custom-table tr:nth-child(even) {
	// background-color: #1b90ff24;
	.ant-table-cell-fix-left {
		// background-color: #1b90ff24;
	}
}
.admin-content {
	height: calc(100vh - 60px);
}

.ft-20 {
	font-size: 20px !important;
}
.ft-15 {
	font-size: 15px !important;
}
.ft-14 {
	font-size: 14px !important;
}
.ft-13 {
	font-size: 13px !important;
}
.ft-12 {
	font-size: 12px !important;
}
.ft-11 {
	font-size: 11px !important;
}
.ft-10 {
	font-size: 10px !important;
}
.bold {
	font-weight: bold;
}

// body {
// 	// font-family: 'Lato', sans-serif !important;
// 	background-color: #f1f2f6 !important;
// }
// .highlightClass {
// 	padding: 0;
// 	background-color: #ffd63f;
// }

// .ant-layout-sider {
// 	background-color: #fff !important;
// }

// .ant-menu.ant-menu-dark,
// .ant-menu-dark .ant-menu-sub,
// .ant-menu.ant-menu-dark .ant-menu-sub {
// 	background-color: rgb(38, 30, 27) !important;
// }
// .ant-page-header {
// 	box-shadow: 1px 1px 5px rgb(255, 100, 0);
// }

// // .required:after {
// //   color: red;
// //   content: " *";
// // }

// .form-input {
// 	border-radius: 8px !important;
// 	background-color: #eeeeee !important;
// 	height: 38px;
// }
// .form-invalid {
// 	border: 1px solid red !important;
// 	background-color: #eeeeee !important;
// 	height: 38px;
// }

// .form-invalid-message {
// 	color: red !important;
// 	font-size: 12px !important;
// }

// .admin-content {
// 	width: 100%;
// 	background: white;
// 	height: calc(100vh - 60px);
// 	// margin-top: auto;
// 	// margin-bottom: auto;
// }

// .pointer-icons {
// 	font-size: 16px;
// 	cursor: pointer;
// }

// .custom-icons {
// 	font-size: 30px;
// }

// .page-header-tiles {
// 	margin: 0px 15px;
// 	@media only screen and (max-width: 850px) {
// 		display: none;
// 	}
// }

// .admin-content {
// 	@media only screen and (max-width: 600px) {
// 		padding-right: 5px;
// 	}

// 	overflow-y: auto;
// }

// .custom-table {
// 	@media only screen and (max-width: 600px) {
// 		overflow-x: scroll;
// 	}
// 	.ant-table-thead > tr > th,
// 	.ant-table-tbody > tr > td .ant-table tfoot > tr > th,
// 	.ant-table tfoot > tr > td {
// 		padding: 8px 8px 8px 8px !important;
// 	}
// 	th {
// 		border-top: 0 !important;
// 		padding: 0 0 0 30px !important;
// 		background: white !important;
// 		border-bottom: 1px solid #dddddd !important;
// 		text-align: left !important;
// 		font-weight: bold !important;
// 		color: #61616b !important;
// 		line-height: 1.42857 !important;
// 		letter-spacing: 0.7px !important;
// 		max-height: 35px !important;
// 		font-size: 13px !important;
// 		// text-transform: uppercase !important;
// 	}

// 	tbody tr:hover td {
// 		background-color: #f5f5f5 !important;
// 	}

// 	tr > td {
// 		line-height: 1.42857 !important;
// 		padding: 8px !important;
// 		vertical-align: top !important;
// 		color: #676a6c !important;
// 	}
// 	.ant-table-footer {
// 		color: #676a6c !important;
// 	}
// 	.ant-table-cell-fix-left {
// 		background-color: inherit !important;
// 	}
// 	.ant-table-cell-fix-left:hover {
// 		background-color: #f5f5f5 !important;
// 	}
// 	.parent-row {
// 		background-color: #f9f9f9 !important;
// 	}
// 	.parent-row > td {
// 		border: none !important;
// 	}
// }

// /***********Ant Overrides************/

// tbody {
// 	tr {
// 		td {
// 			height: 10px !important;
// 			padding: 6px !important;
// 		}
// 	}
// }

// .ant-table-measure-row {
// 	display: none !important;
// }

// .required > div > label {
// 	color: red !important;
// }

// .ant-page-header {
// 	padding: 0 !important;
// }

// .card-title {
// 	font-size: 16px !important;
// }

// .ant-layout-sider-collapsed {
// 	flex: 0 0 40px !important;
// 	max-width: 40px !important;
// 	min-width: 40px !important;
// 	width: 40px !important;
// }

// .ant-layout-sider-trigger {
// 	width: 40px !important;
// }

// .ant-page-header-heading-extra {
// 	margin-top: 10px !important;
// }

// .ant-form-item.custom {
// 	margin-bottom: 0px !important;
// }

// .ant-pagination-next .ant-pagination-item-link {
// 	content: 'Next' !important;
// }

// .anticon {
// 	vertical-align: 0px !important;
// }

// .ant-divider.ant-divider-horizontal {
// 	margin: 1px 0 !important;
// }
// .ant-form-item {
// 	margin-bottom: 10px !important;
// }

// .nav.nav-pills {
// 	@media only screen and (max-width: 450px) {
// 		display: inline-grid !important;
// 	}
// 	// display: inline-flex !important;
// }

// .ant-pagination-item-active {
// 	border-color: lightgrey !important;
// 	background-color: lightgray !important;
// }

// .ant-pagination-item:focus-visible,
// .ant-pagination-item:hover {
// 	border-color: lightgray !important;
// }

// .invoice-dropdown {
// 	.ant-select-selector {
// 		border: none !important;
// 		box-shadow: none !important;
// 	}

// 	.ant-select-arrow {
// 		display: none !important;
// 	}
// }

// .ant-table {
// 	font-size: 13px !important;
// }

// /***********Scroll bars*************/

// .scroller {
// 	scrollbar-color: #0a4c95 !important;
// }

// /* width and Height*/
// ::-webkit-scrollbar {
// 	width: 5px;
// 	height: 5px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
// 	box-shadow: inset 0 0 5px grey;
// 	border-radius: 10px;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
// 	background: grey;
// 	border-radius: 10px;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
// 	background: gray;
// }

// /***********Tables****************/
// .custom-table tr:nth-child(even) {
// 	background-color: #e5f6ec;
// }

// .custom-table tr:nth-child(odd) {
// 	background-color: white;
// }

// .ant-table-thead > tr > th,
// .ant-table-tbody > tr > td .ant-table tfoot > tr > th,
// .ant-table tfoot > tr > td {
// 	padding: 16px 16px !important;
// }

// table {
// 	border: 1px solid #ccc;
// 	border-collapse: collapse;
// }

// .table-input {
// 	width: 100%;
// 	height: 100%;
// 	border: none;
// }

// td.td-ghost {
// 	border-bottom: 1px solid transparent !important;
// 	border-right: 1px solid transparent !important;
// }

// .table-input:focus-visible {
// 	outline: none !important;
// }

// th.th-ghost {
// 	background-color: white !important;
// 	border-bottom: 1px solid transparent !important;
// 	border-right: 1px solid transparent !important;
// 	border-top: 1px solid transparent !important;
// }

// .contact-person-table {
// 	@media only screen and (max-width: 600px) {
// 		width: 98% !important;
// 		overflow-x: scroll !important;
// 	}
// }

// .ant-table-row {
// 	cursor: pointer;
// }

// .invoice-table {
// 	margin-left: 20px;
// }

// // ********** Drawer ********

// // .ant-drawer-content-wrapper {
// //   @media only screen and (max-width: 600px) {
// //     width: 320px;
// //   }

// //   /* Small devices (portrait tablets and large phones, 600px and up) */
// //   @media only screen and (min-width: 600px) {
// //     width: 600px;
// //   }

// //   /* Medium devices (landscape tablets, 768px and up) */
// //   @media only screen and (min-width: 768px) {
// //     width: 720px;
// //   }

// //   /* Large devices (laptops/desktops, 992px and up) */
// //   @media only screen and (min-width: 992px) {
// //     width: 720px;
// //   }

// //   /* Extra large devices (large laptops and desktops, 1200px and up) */
// //   @media only screen and (min-width: 1200px) {
// //     width: 1000px;
// //   }
// // }

// .mt-2 {
// 	margin-top: 0px !important;
// }

// .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
// 	background-color: transparent !important;
// }

// .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
// .ant-menu-dark .ant-menu-item-selected .anticon + span {
// 	color: rgb(255, 100, 0) !important;
// }

// .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
// .ant-menu-dark .ant-menu-item-selected .anticon {
// 	color: rgb(255, 100, 0) !important;
// }

// .ant-menu-dark .ant-menu-item:hover {
// 	background-color: rgba(55, 48, 45, 1) !important;
// }

// .ant-layout-sider-trigger {
// 	background-color: rgba(55, 48, 45, 1) !important;
// }

// .ant-menu-vertical > .ant-menu-item,
// .ant-menu-vertical-left > .ant-menu-item,
// .ant-menu-vertical-right > .ant-menu-item,
// .ant-menu-inline > .ant-menu-item,
// .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
// .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
// .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
// .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
// 	height: 25px !important;
// 	line-height: 25px !important;
// }

.ant-input-number {
	.ant-input-number-handler-wrap {
		display: none !important;
	}
}

.Toastify {
	.Toastify__toast-container {
		width: 400px;
		.Toastify__toast {
			.Toastify__toast-body {
				.push-notification {
					display: flex;
					&.SUCCESS {
						.indicator {
							background: #21a47a;
						}
						.icon {
							color: #21a47a;
						}
					}
					&.INFO {
						.indicator {
							background: #0a74b5;
						}
						.icon {
							color: #0a74b5;
						}
					}
					&.ERROR {
						.indicator {
							background: #ea2a6b;
						}
						.icon {
							color: #ea2a6b;
						}
					}
					&.WARNING {
						.indicator {
							background: #eba631;
						}
						.icon {
							color: #eba631;
						}
					}
					.indicator {
						height: auto;
						width: 1.5%;
						border-radius: 10px;
						margin-right: 10px;
					}
					.icon {
						display: flex;
						align-items: center;
						margin-right: 10px;
						font-size: 20px;
					}
					.push-notification-content {
						width: 98.5%;
						.push-notification-title {
							font-weight: bold;
							color: #000;
						}
						.push-notification-text {
							margin: 0;
						}
					}
				}
			}
		}
	}
}

.ant-form-horizontal {
	&.required_in_right {
		.ant-form-item-required {
			&::after {
				display: inline-block;
				margin-right: 4px;
				color: #ff4d4f;
				font-size: 14px;
				line-height: 1;
				content: '*';
			}
			&::before {
				display: none !important;
			}
		}
	}
}