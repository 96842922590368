.tracking-page {
	height: 100vh;
	.left_area {
		background-color: rgb(255, 255, 255);
		padding: 16px 4px 4px 16px;
		// filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 14px);
		box-shadow: 0 4px 14px rgba(0, 0, 0, 0.25);
	}
	.label_value {
		margin-bottom: 0.5rem;
		.label {
			font-size: 1rem;
			font-weight: bold;
		}
		.colon {
			font-size: 1rem;
			font-weight: bold;
		}
		.value {
			font-size: 1rem;
			color: #64748b;
		}
	}
	background-color: #fff;
	.map-container {
		height: 100%;
	}
	color: #000;
	background: #f5f5f5;
	.ant-picker-input {
		input {
			color: #000;
		}
	}
	.ant-input-disabled,
	.ant-input[disabled],
	.ant-picker.ant-picker-disabled,
	.ant-picker .ant-picker-input > input-disabled,
	.ant-picker .ant-picker-input > input[disabled] {
		color: #000;
	}
	.ant-input-number-disabled,
	.ant-select-disabled {
		color: #000;
		background: #f5f5f5;
	}
	.ant-select-selection-item {
		color: #000;
	}
	.ant-btn[disabled] {
		color: #000;
		background: #f5f5f5;
	}
}

.gm-style-iw-tc {
	display: none;
}

.gm-style .gm-style-iw-c {
	top: 5px;
}

.gm-style-iw-c {
	box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2) !important;
}

.gm-ui-hover-effect {
	border-width: 1px !important;
	border-style: solid !important;
	/* background-color: rgba(0, 0, 0, 0.4) !important; */
	border-radius: 50% !important;
	border: none !important;
	top: 5px !important;
	right: 5px !important;
	box-shadow: 0 6px 20px rgb(0 0 0 / 48%) !important;
}

.gm-ui-hover-effect span {
	color: #ffff;
}

.gm-style-iw {
	padding-left: 20px !important;
	padding-top: 10px !important;
	padding-bottom: 0 !important;
	padding-right: 0 !important;
	width: 3%;
}

.info_container,
.image_style {
	width: 150px;
	height: 150px;
}

.info_container {
	width: 100%;
}

.info_container_row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.info_container_row .left {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.info_container_row .right {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
}

.info_container_row .left span {
	color: #64748b;
}
.info_container_row .right span {
	color: #64748b;
}

.info_container_row .left .value {
	font-weight: bold;
	font-size: 1rem;
	margin-bottom: 0.5rem;
	margin-top: 0 !important;
}
.info_container_row .right .value {
	font-weight: bold;
	font-size: 1rem;
	margin-bottom: 0.5rem;
	margin-top: 0 !important;
}
.gm-style-iw-d {
	min-height: 240px;
}

.gmnoprint,
.gm-fullscreen-control {
	display: none;
}

.asset_title {
	margin-top: 5px;
}
