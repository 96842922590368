.logo_class {
	all: unset;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	font-size: 20px;
	cursor: pointer;
}

.overflow-scroll {
	height: calc(100vh - 65px);
	overflow-y: auto;
}

.pointer-icons {
	font-size: 16px;
	cursor: pointer;
}

.loader {
	height: 100vh;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #fff;
	z-index: 2;
	opacity: 1;
	animation: loading 2s forwards;
	&.hide {
		opacity: 0;
		top: -100vh;
	}
}

@keyframes loading {
	0% {
		z-index: 100;
		opacity: 1;
	}
	90% {
		z-index: 100;
		opacity: 1;
	}
	100% {
		z-index: -100 !important;
		opacity: 0 !important;
		display: none;
	}
}
