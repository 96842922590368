.custom-header {
	width: 100%;
	.ant-page-header-heading {
		padding-left: 10px;
		width: 100%;
		.logo {
			cursor: pointer;
		}
		&-extra {
			display: flex;
			justify-content: flex-end;
			.profile-dropdown {
				cursor: pointer;
				display: flex;
				gap: 10px;
				.name {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					max-width: 150px;
				}
			}
		}
	}
}
